import React, { useState, useEffect } from 'react';
import {
  Globe,
  Building,
  Bitcoin,
  CreditCard,
  Check,
  Target,
  Rocket,
  TrendingUp,
  Shield,
  Landmark,
  BarChart2,
} from 'lucide-react';
import './Form.css';

const countryPrefixes = [
  { code: 'ES', name: 'España', prefix: '+34' },
  { code: 'AR', name: 'Argentina', prefix: '+54' },
  { code: 'BO', name: 'Bolivia', prefix: '+591' },
  { code: 'CL', name: 'Chile', prefix: '+56' },
  { code: 'CO', name: 'Colombia', prefix: '+57' },
  { code: 'CR', name: 'Costa Rica', prefix: '+506' },
  { code: 'CU', name: 'Cuba', prefix: '+53' },
  { code: 'EC', name: 'Ecuador', prefix: '+593' },
  { code: 'SV', name: 'El Salvador', prefix: '+503' },
  { code: 'GT', name: 'Guatemala', prefix: '+502' },
  { code: 'HN', name: 'Honduras', prefix: '+504' },
  { code: 'MX', name: 'México', prefix: '+52' },
  { code: 'NI', name: 'Nicaragua', prefix: '+505' },
  { code: 'PA', name: 'Panamá', prefix: '+507' },
  { code: 'PY', name: 'Paraguay', prefix: '+595' },
  { code: 'PE', name: 'Perú', prefix: '+51' },
  { code: 'DO', name: 'República Dominicana', prefix: '+1' },
  { code: 'UY', name: 'Uruguay', prefix: '+598' },
  { code: 'VE', name: 'Venezuela', prefix: '+58' }
].sort((a, b) => a.name.localeCompare(b.name));

const serviceData = [
  {
    icon: Building,
    title: 'Bienes Raíces Premium',
    description: 'Inversiones en propiedades de alto rendimiento en Dubái',
    details: [
      'Rendimientos hasta 8% anual',
      'Proyectos en zonas de mayor crecimiento',
      'Asesoría legal y financiera completa'
    ]
  },
  {
    icon: Bitcoin,
    title: 'Estrategias Crypto',
    description: 'Inversión inteligente en criptoactivos',
    details: [
      'Cartera diversificada de criptomonedas',
      'Análisis de mercado en tiempo real',
      'Gestión de riesgos especializada'
    ]
  },
  {
    icon: Globe,
    title: 'Golden Visa',
    description: 'Residencia en Emiratos Árabes Unidos',
    details: [
      'Proceso legal simplificado',
      'Requisitos de inversión accesibles',
      'Beneficios fiscales y de movilidad'
    ]
  },
  {
    icon: Target,
    title: 'Consultoría Internacional',
    description: 'Expansión de negocios globales',
    details: [
      'Estrategias de internacionalización',
      'Conexiones empresariales en Dubái',
      'Soporte legal y financiero'
    ]
  },
  {
    icon: Rocket,
    title: 'Startups & Innovación',
    description: 'Ecosistema de emprendimiento',
    details: [
      'Inversión en startups emergentes',
      'Acceso a fondos de capital',
      'Networking internacional'
    ]
  },
  {
    icon: TrendingUp,
    title: 'Inversión Estratégica',
    description: 'Portafolio de alto rendimiento',
    details: [
      'Diversificación geográfica',
      'Análisis de tendencias globales',
      'Gestión personalizada'
    ]
  }
];

const DubaiLandingFormCompany = () => {

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    countryPrefix: '', // Ya no hay prefijo predeterminado
    email: '',
    message: '',
    investmentInterest: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const newErrors = {};

    if (!/^[A-Za-zÀ-ÿ\s]{2,}$/.test(formData.name)) {
      newErrors.name = 'Ingresa tu nombre completo';
    }

    if (!formData.countryPrefix) {
      newErrors.countryPrefix = 'Selecciona tu país';
    }

    if (!/^\d{8,12}$/.test(formData.phone)) {
      newErrors.phone = 'Número entre 8 y 12 dígitos';
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Correo electrónico inválido';
    }

    if (!formData.investmentInterest) {
      newErrors.investmentInterest = 'Selecciona un área';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: null,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const fullPhoneNumber = `${formData.countryPrefix}${formData.phone}`;

      const formDataToSend = new URLSearchParams({
        ...formData,
        phone: fullPhoneNumber,
      });

      const response = await fetch('https://hooks.zapier.com/hooks/catch/19963547/2rcs665/', {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error('Error al enviar los datos');
      }

      setFormData({
        name: '',
        phone: '',
        countryPrefix: '',
        email: '',
        message: '',
        investmentInterest: ''
      });

      alert('¡Contacto enviado! Diego Carranza te responderá pronto.');
    } catch (error) {
      console.error('Error:', error);
      alert('Error al enviar. Intenta nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  const personalizedServices = [
    {
      icon: TrendingUp,
      title: "Estrategias de Inversión Personalizadas",
      description: "Diseñamos soluciones de inversión adaptadas a tu perfil financiero y objetivos específicos.",
      details: [
        "Análisis detallado de tu situación financiera",
        "Recomendaciones basadas en tu tolerancia al riesgo",
        "Seguimiento y ajuste continuo de estrategias"
      ]
    },
    {
      icon: Shield,
      title: "Protección y Crecimiento",
      description: "Maximiza tu patrimonio con un enfoque equilibrado que prioriza seguridad y rentabilidad.",
      details: [
        "Cartera diversificada y optimizada",
        "Minimización de riesgos potenciales",
        "Estrategias de preservación de capital"
      ]
    },
    {
      icon: Globe,
      title: "Inversiones Internacionales",
      description: "Accede a oportunidades globales con asesoramiento experto en mercados internacionales.",
      details: [
        "Acceso a mercados emergentes",
        "Análisis de tendencias globales",
        "Estrategias de inversión internacional"
      ]
    }
  ];

  const investmentServices = [
    {
      icon: Bitcoin,
      title: "Crypto Company",
      description: "Estrategias especializadas en el mercado de criptomonedas y blockchain.",
      details: [
        "Apertura de cuentas bancarias",
        "Visas para negocios",
        "Análisis de Mercado y Zonas francas",
        "Gestión de procesos legales"
      ]
    },
    {
      icon: Landmark,
      title: "Bank and Financial Services",
      description: "Inversiones inmobiliarias estratégicas con alto potencial de rendimiento.",
      details: [
        "Apertura de cuentas bancarias",
        "Visas para los integrantes",
        "Zonas Francas y Mainland en Dubai",
        "Estrategias de inversión en bienes raíces"
      ]
    },
    {
      icon: CreditCard,
      title: "Technology and Innovation",
      description: "Oportunidades de inversión en empresas emergentes con alto potencial.",
      details: [
        "Consultoría en empresas emergentes",
        "Visa para integrantes",
        "Posibles oportunidades de inversión y mercados",
      ]
    }
  ];

  return (
    <div className="investment-landing">
      <div className="form-section">
        <h1 className="form-title">
          Abre Tu Empresa en <span style={{ color: 'var(--orange-prim)' }}>Dubái</span> YA
        </h1>
        <p className="form-subtitle">
          Transforma tu patrimonio con asesoría especializada
        </p>

        <form onSubmit={handleSubmit} className="investment-form">
          <input
            className="form-input"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Nombre Completo"
            disabled={loading}
          />
          {errors.name && <p style={{ color: 'var(--red-prim)', margin: 0 }}>{errors.name}</p>}

          <select
            className="form-input"
            name="investmentInterest"
            value={formData.investmentInterest}
            onChange={handleChange}
            disabled={loading}
          >
            <option value="">Compañia de Interés</option>
            <option value="turismo_hospitalidad">Turismo y Hospitalidad</option>
            <option value="servicios_financieros">Servicios Financieros</option>
            <option value="e-commerce_reventa">E-Commerce y Reventa</option>
            <option value="busco_asesoria">Busco asesoria</option>
            <option value="otros">Otros</option>
          </select>
          {errors.investmentInterest && <p style={{ color: 'var(--red-prim)', margin: 0 }}>{errors.investmentInterest}</p>}

          <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
            <select
              className="form-input"
              style={{ width: '40%' }}
              name="countryPrefix"
              value={formData.countryPrefix}
              onChange={handleChange}
              disabled={loading}
            >
              <option value="">País</option>
              {countryPrefixes.map((country) => (
                <option key={country.code} value={country.prefix}>
                  {country.name} ({country.prefix})
                </option>
              ))}
            </select>
            <input
              className="form-input"
              style={{ width: '60%' }}
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Número de teléfono"
              disabled={loading}
            />
          </div>
          {errors.countryPrefix && <p style={{ color: 'var(--red-prim)', margin: 0 }}>{errors.countryPrefix}</p>}
          {errors.phone && <p style={{ color: 'var(--red-prim)', margin: 0 }}>{errors.phone}</p>}

          <input
            className="form-input"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Correo Electrónico"
            disabled={loading}
          />
          {errors.email && <p style={{ color: 'var(--red-prim)', margin: 0 }}>{errors.email}</p>}

          <textarea
            className="form-input"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Mensaje opcional"
            style={{ minHeight: '100px' }}
            disabled={loading}
          />

          <button
            type="submit"
            className="submit-button"
            disabled={loading}
          >
            {loading ? 'Enviando...' : 'Agenda Tu Consulta Gratis'}
          </button>
        </form>

      </div>

      <div className="services-section">
        <h2 className="form-title">
          Servicios de <span style={{ color: 'var(--orange-prim)' }}>Inversión</span>
        </h2>
        <p className="form-subtitle" style={{ marginBottom: '2rem' }}>
          Descubre oportunidades únicas con asesoría especializada. Transformamos tus metas financieras en estrategias concretas y rentables.
        </p>

        <h3 style={{
          color: 'var(--orange-prim)',
          textAlign: 'center',
          marginBottom: '1.5rem',
          fontSize: '1.5rem',
          fontWeight: 'bold'
        }}>
          Servicios Personalizados
        </h3>
        <div className="services-grid">
          {personalizedServices.map((service, index) => (
            <div key={index} className="service-card">
              <service.icon
                size={48}
                color="var(--orange-prim)"
                style={{ marginBottom: '1rem' }}
              />
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
              <ul style={{
                listStyle: 'none',
                color: 'var(--text-secondary)',
                marginTop: '1rem',
                textAlign: 'left'
              }}>
                {service.details.map((detail, idx) => (
                  <li key={idx} style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.5rem'
                  }}>
                    <Check
                      size={16}
                      color="var(--orange-prim)"
                      style={{ marginRight: '0.5rem' }}
                    />
                    {detail}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <h3 style={{
          color: 'var(--orange-prim)',
          textAlign: 'center',
          marginTop: '2rem',
          marginBottom: '1.5rem',
          fontSize: '1.5rem',
          fontWeight: 'bold'
        }}>
          Otras Opciones de Inversión
        </h3>
        <div className="services-grid">
          {investmentServices.map((service, index) => (
            <div key={index} className="service-card">
              <service.icon
                size={48}
                color="var(--orange-prim)"
                style={{ marginBottom: '1rem' }}
              />
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
              <ul style={{
                listStyle: 'none',
                color: 'var(--text-secondary)',
                marginTop: '1rem',
                textAlign: 'left'
              }}>
                {service.details.map((detail, idx) => (
                  <li key={idx} style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.5rem'
                  }}>
                    <Check
                      size={16}
                      color="var(--orange-prim)"
                      style={{ marginRight: '0.5rem' }}
                    />
                    {detail}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div style={{
          marginTop: '2rem',
          textAlign: 'center',
          borderTop: '1px solid var(--text-secondary)',
          paddingTop: '2rem'
        }}>
          <p style={{
            color: 'var(--text-secondary)',
            maxWidth: '800px',
            margin: '0 auto 1.5rem',
            lineHeight: '1.6'
          }}>
            Nuestro compromiso es guiarte hacia el éxito financiero. Con un equipo de expertos dedicados,
            tecnología de vanguardia y estrategias personalizadas, te acompañamos en cada paso de tu journey de inversión.
          </p>
          <button
            style={{
              backgroundColor: 'var(--orange-prim)',
              color: 'var(--dark-bg)',
              border: 'none',
              padding: '0.75rem 1.5rem',
              borderRadius: '8px',
              fontWeight: 'bold',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
              display: 'inline-flex',
              alignItems: 'center',
              gap: '0.5rem'
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = 'var(--red-prim)'}
            onMouseOut={(e) => e.target.style.backgroundColor = 'var(--orange-prim)'}
          >
            Agenda tu Consulta Gratuita
            <BarChart2 size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DubaiLandingFormCompany;