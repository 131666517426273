import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Home from "../Components/Home/Home";
import Call from "../Components/call/call";
import HamburgerMenu from "../Components/Nav/Navbar";
import Footer from "../Components/Footer/footer";
import Contactnumber from "../Components/contactNumbers/contactNumbers";
import Workteam from "../Components/workteam/workteam";
import Goldenvisa from "../Components/goldenVisa/goldenvisa";
import Cripto from "../Components/criptomonedas/criptomonedas";
import Realstate from "../Components/realstate/realstate";
import Taxes from "../Components/taxes/taxes";
import Contactbutton from "../Components/contactButton/contactButton";
import Podcast from "../Components/podcast/podcast";
import Emigrant from "../Components/emigrant/Emigrant";
import Guests from "../Components/guests/guests";
import Training from "../Components/training/training";
import Partner from "../Components/partner/partner";
import Notfound from "../Components/Notfound/notfound";
import ScrollToTop from "./scrollToTop";
import { HelmetProvider } from 'react-helmet-async';
import ContactForm from "../Components/Form/Form";
import DubaiLandingFormCompany from "../Components/Form/Formcompany";

function App() {

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Call />
        <HamburgerMenu />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/quien-es-inversiones-dubai" element={<Workteam />} />
          <Route exact path="/obtener-residencia-dubai" element={<Goldenvisa />}/>
          <Route exact path="/invertir-criptomonedas-dubai" element={<Cripto />}/>
          <Route exact path="/invertir-bienes-raises-dubai" element={<Realstate />}/>
          <Route exact path="/inversiones-impuestos-dubai" element={<Taxes />}/>
          <Route exact path="/podcast-inversiones-dubai" element={<Podcast />}/>
          <Route exact path="/emigrar-a-dubai" element={<Emigrant />}/>
          <Route exact path="/podcast-invitados-dubai" element={<Guests />}/>
          <Route exact path="/trabajar-con-inversiones-dubai" element={<Partner />}/>
          <Route exact path="/aprende-sobre-inversiones-dubai" element={<Training />}/>
          <Route path="*" element={<Notfound />} />
          <Route path="/form-contact" element={<ContactForm />}/>
          <Route path="/form-company" element={<DubaiLandingFormCompany />}/>
        </Routes>
        <Contactbutton />
        <Contactnumber />
        <Footer />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;